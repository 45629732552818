<template>
  <div>
    <div class="outer" v-if="!registrationFlag">
      <br /><br />
      <v-sheet id="scrolling-techniques-7" class="overflow-y-auto">
        <v-container v-if="item">
          <br />
          <div>
            <v-row class="card-div">
              <h1 style="color: grey darken-1">Request To Book</h1>
            </v-row>
            <v-row style="padding: 5px">
              <div class="col-sm-12 col-lg-7">
                <v-container style="overflow: auto">
                  <v-row>
                    <h3 style="color: grey darken-1; height: 20px">
                      Your Trip
                    </h3>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <h3
                        style="
                          color: grey darken-1;
                          height: 10px;
                          margin-left: -10px;
                        "
                      >
                        Dates
                      </h3>
                      <br />
                    </v-col>
                    <v-col cols="4"></v-col>
                    <v-col cols="4" v-if="this.midFlag == false">
                      <v-dialog
                        transition="dialog-top-transition"
                        max-width="800"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <h5 style="color: grey darken-1">
                            <u v-bind="attrs" v-on="on">Edit</u>
                            <!-- <span><u>Edit</u></span> -->
                          </h5>
                        </template>
                        <template v-slot:default="dialog">
                          <v-card>
                            <!-- <v-toolbar color="primary" dark
                            >Opening from the top</v-toolbar
                          > -->
                            <v-card-text>
                              <div class="text-h2 pa-12">
                                <v-card-title>
                                  <h3>Edit your dates</h3>
                                </v-card-title>
                                <v-divider></v-divider>

                                <v-card-text>
                                  <v-container>
                                    <v-col>
                                      <v-row no-gutters>
                                        <v-col cols="6" sm="6">
                                          <v-menu
                                            ref="startMenu"
                                            :close-on-content-click="false"
                                            :return-value.sync="trip.start"
                                            offset-y
                                            min-width="290px"
                                          >
                                            <template
                                              v-slot:activator="{ on, attrs }"
                                            >
                                              <v-text-field
                                                v-model="trip.start"
                                                label="Check in"
                                                append-icon="mdi-calendar"
                                                outlined
                                                readonly
                                                color="yellow darken-1"
                                                v-bind="attrs"
                                                v-on="on"
                                              ></v-text-field>
                                            </template>
                                            <v-date-picker
                                              v-model="dateStart"
                                              :min="
                                                new Date()
                                                  .toISOString()
                                                  .substr(0, 10)
                                              "
                                              no-title
                                              scrollable
                                              color="yellow darken-1"
                                            >
                                              <v-spacer></v-spacer>
                                              <v-btn
                                                text
                                                color="yellow darken-1"
                                                @click="
                                                  $refs.startMenu.isActive = false
                                                "
                                              >
                                                Cancel
                                              </v-btn>
                                              <v-btn
                                                text
                                                color="yellow darken-1"
                                                @click="
                                                  $refs.startMenu.save(
                                                    dateStart
                                                  ),
                                                    dateConvertForStartDate()
                                                "
                                              >
                                                OK
                                              </v-btn>
                                            </v-date-picker>
                                          </v-menu>
                                        </v-col>
                                        <v-col cols="6" sm="6">
                                          <v-menu
                                            ref="endMenu"
                                            :close-on-content-click="false"
                                            :return-value.sync="trip.end"
                                            offset-y
                                            min-width="290px"
                                          >
                                            <template
                                              v-slot:activator="{ on, attrs }"
                                            >
                                              <v-text-field
                                                v-model="trip.end"
                                                label="Check out"
                                                append-icon="mdi-calendar"
                                                color="yellow darken-1"
                                                readonly
                                                outlined
                                                v-bind="attrs"
                                                v-on="on"
                                              ></v-text-field>
                                            </template>
                                            <v-date-picker
                                              v-model="dateEnd"
                                              :min="getMinDate()"
                                              no-title
                                              scrollable
                                              color="yellow darken-1"
                                            >
                                              <v-spacer></v-spacer>
                                              <v-btn
                                                text
                                                color="yellow darken-1"
                                                @click="
                                                  $refs.endMenu.isActive = false
                                                "
                                              >
                                                Cancel
                                              </v-btn>
                                              <v-btn
                                                text
                                                color="yellow darken-1"
                                                @click="
                                                  $refs.endMenu.save(dateEnd),
                                                    Dateconvertforenddate()
                                                "
                                              >
                                                OK
                                              </v-btn>
                                            </v-date-picker>
                                          </v-menu>
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                  </v-container>
                                </v-card-text>
                              </div>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                              <v-btn
                                text
                                @click="(dialog.value = false), page(), init()"
                                color="yellow darken-1"
                                >Save</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </template>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-row style="margin-top: -5px !important">
                    <p v-if="this.midFlag">
                      {{ convertDate(this.finalstartdate) }} -
                      {{ convertDate(this.finalenddate) }}
                    </p>
                    <p v-else>
                      {{ convertDate(this.start1) }} -
                      {{ convertDate(this.end1) }}
                    </p>
                  </v-row>
                  <v-row style="margin-top: 5px !important">
                    <v-col cols="4">
                      <h3
                        style="
                          color: grey darken-1;
                          height: 10px;
                          margin-left: -10px;
                        "
                      >
                        Guests
                      </h3>
                      <br />
                    </v-col>
                    <v-col cols="4"></v-col>
                    <v-col cols="4" v-if="this.midFlag == false">
                      <v-dialog
                        transition="dialog-top-transition"
                        max-width="600"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <h5 style="color: grey darken-1">
                            <u v-bind="attrs" v-on="on">Edit</u>
                            <!-- <span><u>Edit</u></span> -->
                          </h5>
                        </template>
                        <template v-slot:default="dialog">
                          <v-card>
                            <!-- <v-toolbar color="primary" dark
                            >Opening from the top</v-toolbar
                          > -->
                            <v-card-text>
                              <div class="text-h2 pa-12">
                                <v-card-title>
                                  <span class="text-h5"
                                    >Enter guest details</span
                                  >
                                </v-card-title>
                                <v-divider></v-divider>

                                <v-card-text>
                                  <v-container>
                                    <v-col>
                                      <v-row class="popupstyle">
                                        <v-col class="roomstyle1">
                                          <h3>Adults</h3>
                                        </v-col>
                                        <v-col
                                          cols="12"
                                          sm="6"
                                          class="boxstyle"
                                        >
                                          <v-text-field
                                            class="boxstyle"
                                            v-model="adult"
                                            style="height: 50px"
                                            rounded
                                            outlined
                                            color="yellow darken-1"
                                            track-color="grey"
                                            always-dirty
                                          >
                                            <template v-slot:append>
                                              <v-icon
                                                v-bind:disabled="adult < 1"
                                                app
                                                :color="color"
                                                @click="decrement"
                                              >
                                                mdi-minus
                                              </v-icon>
                                              <v-icon
                                                :color="color"
                                                @click="increment"
                                              >
                                                mdi-plus
                                              </v-icon>
                                            </template>
                                          </v-text-field>
                                        </v-col>
                                      </v-row>

                                      <v-row class="popupstyle">
                                        <v-col class="roomstyle1">
                                          <h3>Children</h3>
                                        </v-col>
                                        <v-col
                                          cols="12"
                                          sm="6"
                                          class="boxstyle"
                                        >
                                          <v-text-field
                                            class="boxstyle"
                                            v-model="children"
                                            style="height: 60px"
                                            rounded
                                            outlined
                                            color="yellow darken-1"
                                            track-color="grey"
                                            always-dirty
                                          >
                                            <template v-slot:append>
                                              <v-icon
                                                v-bind:disabled="children < 1"
                                                :color="color"
                                                @click="decrement1"
                                              >
                                                mdi-minus
                                              </v-icon>
                                              <v-icon
                                                :color="color"
                                                @click="increment1"
                                              >
                                                mdi-plus
                                              </v-icon>
                                            </template>
                                          </v-text-field>
                                        </v-col>
                                      </v-row>

                                      <v-row
                                        class="popupstyle"
                                        style="margin-top: auto"
                                      >
                                        <v-col class="roomstyle1">
                                          <h3>Infrant</h3>
                                        </v-col>
                                        <v-col
                                          cols="12"
                                          sm="6"
                                          class="boxstyle"
                                        >
                                          <v-text-field
                                            class="boxstyle"
                                            v-model="infrant"
                                            style="height: 40px"
                                            color="yellow darken-1"
                                            rounded
                                            outlined
                                            track-color="grey"
                                            always-dirty
                                          >
                                            <template v-slot:append>
                                              <v-icon
                                                v-bind:disabled="infrant < 1"
                                                :color="color"
                                                @click="decrement2"
                                              >
                                                mdi-minus
                                              </v-icon>
                                              <v-icon
                                                :color="color"
                                                @click="increment2"
                                              >
                                                mdi-plus
                                              </v-icon>
                                            </template>
                                          </v-text-field>
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                  </v-container>
                                  <!-- <small>*indicates required field</small> -->
                                </v-card-text>
                              </div>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                              <v-btn
                                text
                                @click="
                                  dialog.value = false;
                                  pageguest();
                                  init();
                                "
                                color="yellow darken-1"
                                >Close</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </template>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-row style="margin-top: -5px !important">
                    <div style="margin-left: 3px">
                      <p>Adult:{{ this.adult }}</p>
                      <p>Children:{{ this.children }}</p>
                      <p>Infant:{{ this.infrant }}</p>
                    </div>
                  </v-row>

                  <div
                    v-if="
                      item.is_transportation_included != 0 &&
                      this.midFlag == false
                    "
                  >
                    <v-row>
                      <v-col cols="4">
                        <h3
                          style="
                            color: grey darken-1;
                            margin-left: -10px;
                            margin-top: 5px;
                          "
                        >
                          Package modification
                        </h3>
                      </v-col>
                      <v-col cols="4"></v-col>
                      <v-col cols="4">
                        <h5 style="color: grey darken-1">
                          <v-dialog
                            v-model="dialog"
                            fullscreen
                            hide-overlay
                            transition="dialog-bottom-transition"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <u
                                v-bind="attrs"
                                v-on="on"
                                @click="init(), toggle(), Edit()"
                                >Edit</u
                              >
                            </template>
                            <v-card style="background-color: #eeeeee">
                              <v-toolbar
                                dark
                                color="white"
                                style="
                                  z-index: 999;
                                  position: fixed;
                                  width: 100%;
                                "
                              >
                                <v-btn
                                  color="black"
                                  icon
                                  dark
                                  @click="dialog = false"
                                >
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title>
                                  <router-link to="/" style="color: black">
                                    <img
                                      src="../assets/img/logo-01.png"
                                      class="logostyle"
                                      alt="..."
                                  /></router-link>
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-items>
                                  <v-btn
                                    color="yellow darken-4"
                                    dark
                                    text
                                    @click="(dialog = false), page1()"
                                  >
                                    Save
                                  </v-btn>
                                </v-toolbar-items>
                              </v-toolbar>
                              <!-- working -->
                              <br /><br /><br /><br />
                              <v-container>
                                <div style="text-align: center">
                                  <h2 class="hedrstyle">
                                    Buddy's edit your Package's
                                  </h2>
                                </div>
                                <br /><br />

                                <v-row style="justify-content: center">
                                  <v-col cols="auto">
                                    <h3 style="color: grey darken-1">
                                      Local Tours(Service available within 100
                                      miles of tourist destination)
                                    </h3>
                                  </v-col>

                                  <v-col cols="auto">
                                    <v-switch
                                      inset
                                      color="amber darken-2"
                                      v-model="switch1"
                                      @click="switched()"
                                      style="margin-top: 0"
                                    ></v-switch>
                                  </v-col>
                                </v-row>
                                <div v-if="this.localTourFlag == 1">
                                  <br />
                                  <v-container>
                                    <h3>For Meal Modifications</h3>
                                    <v-card class="packagestyle">
                                      <v-row>
                                        <v-col cols="12" sm="12">
                                          <v-textarea
                                            v-model="mealmodification"
                                            height="100px"
                                            label="Edit your Meal"
                                            auto-grow
                                            outlined
                                            rows="1"
                                            color="yellow darken-4"
                                            row-height="15"
                                            class="local"
                                          ></v-textarea>
                                        </v-col>
                                      </v-row>
                                    </v-card>
                                  </v-container>
                                </div>
                                <div v-if="this.localTourFlag == 0">
                                  <div
                                    v-for="(item, index) in this.arrayToIterate"
                                    v-bind:key="index"
                                    max-height="35px"
                                    max-width="95% "
                                  >
                                    <h4
                                      style="
                                        color: grey darken-1;
                                        padding-left: 30px;
                                      "
                                    >
                                      Local Tours Day {{ item }}
                                    </h4>
                                    <br />

                                    <v-card class="packagestyle">
                                      <!-- v-if="index < 0" -->
                                      <v-row
                                        style="
                                          display: flex;
                                          justify-content: center;
                                        "
                                      >
                                        <v-col>
                                          <v-item-group
                                            v-model="group2"
                                            :multiple="multiple"
                                            style="
                                              display: flex;
                                              justify-content: center;
                                              align-items: center;
                                            "
                                          >
                                            <v-item
                                              #default="{ active, toggle }"
                                            >
                                              <v-switch
                                                @change="
                                                  toggle(!active),
                                                    inputObject(
                                                      $event,
                                                      item,
                                                      '1'
                                                    )
                                                "
                                                label="Pick up / Drop included"
                                                :input-value="active"
                                                hide-details
                                                inset
                                                on
                                                color="amber darken-2"
                                                class="switchclass"
                                              ></v-switch>
                                            </v-item>
                                            <div
                                              v-if="
                                                mealdetails
                                                  .sight_seen_details[0]
                                                  .service_for_4h == true
                                              "
                                            >
                                              <v-item
                                                #default="{ active, toggle }"
                                              >
                                                <v-switch
                                                  @change="
                                                    toggle(!active),
                                                      inputObject(
                                                        $event,
                                                        item,
                                                        '4'
                                                      )
                                                  "
                                                  label="Service for 4 hours"
                                                  :input-value="active"
                                                  hide-details
                                                  inset
                                                  on
                                                  color="amber darken-2"
                                                  class="switchclass"
                                                ></v-switch>
                                              </v-item>
                                            </div>
                                            <div
                                              v-if="
                                                mealdetails
                                                  .sight_seen_details[0]
                                                  .service_for_6h == true
                                              "
                                            >
                                              <v-item
                                                #default="{ active, toggle }"
                                              >
                                                <v-switch
                                                  @change="
                                                    toggle(!active),
                                                      inputObject(
                                                        $event,
                                                        item,
                                                        '6'
                                                      )
                                                  "
                                                  label="Service for 6 hours"
                                                  :input-value="active"
                                                  hide-details
                                                  inset
                                                  on
                                                  color="amber darken-2"
                                                  class="switchclass"
                                                ></v-switch>
                                              </v-item>
                                            </div>
                                            <div
                                              v-if="
                                                mealdetails
                                                  .sight_seen_details[0]
                                                  .service_for_8h == true
                                              "
                                            >
                                              <v-item
                                                #default="{ active, toggle }"
                                              >
                                                <v-switch
                                                  @change="
                                                    toggle(!active),
                                                      inputObject(
                                                        $event,
                                                        item,
                                                        '8'
                                                      )
                                                  "
                                                  label="Service for 8 hours"
                                                  :input-value="active"
                                                  hide-details
                                                  inset
                                                  on
                                                  color="amber darken-2"
                                                  class="switchclass"
                                                ></v-switch>
                                              </v-item>
                                            </div>
                                          </v-item-group>
                                        </v-col>
                                      </v-row>
                                    </v-card>
                                  </div>
                                </div>

                                <v-container>
                                  <h3>For Local Tour's Modifications</h3>
                                  <v-card class="packagestyle">
                                    <v-row>
                                      <v-col cols="12" md="12">
                                        <v-textarea
                                          height="100px"
                                          v-model="localtourmodification"
                                          label="Edit your tour"
                                          auto-grow
                                          outlined
                                          rows="1"
                                          color="yellow darken-4"
                                          row-height="15"
                                          class="local"
                                        ></v-textarea>
                                      </v-col>
                                    </v-row>
                                  </v-card>
                                </v-container>

                                <div v-if="this.localTourFlag == 0">
                                  <br />

                                  <div
                                    v-for="(item, index) in this.arrayToIterate"
                                    v-bind:key="index"
                                    max-height="35px"
                                    max-width="95% "
                                  >
                                    <h4>Meal</h4>
                                    <br />
                                    <h4
                                      style="
                                        color: grey darken-1;
                                        padding-left: 30px;
                                      "
                                    >
                                      Meal For the Day {{ item }}:
                                    </h4>
                                    <br />

                                    <v-card class="packagestyle">
                                      <v-row
                                        style="
                                          display: flex;
                                          justify-content: center;
                                        "
                                      >
                                        <v-col>
                                          <v-item-group
                                            v-model="group1"
                                            multiple
                                            style="
                                              display: flex;
                                              justify-content: center;
                                              align-items: center;
                                            "
                                          >
                                            <div
                                              v-if="
                                                mealdetails.meal_details[0]
                                                  .breakfast == true
                                              "
                                            >
                                              <v-item
                                                #default="{ active, toggle }"
                                              >
                                                <v-switch
                                                  @change="
                                                    toggle(!active),
                                                      inputObject1(
                                                        $event,
                                                        item,
                                                        'Breakfast'
                                                      )
                                                  "
                                                  label="Breakfast"
                                                  :input-value="active"
                                                  hide-details
                                                  inset
                                                  on
                                                  color="amber darken-2"
                                                  class="switchclass"
                                                ></v-switch>
                                              </v-item>
                                            </div>

                                            <div
                                              v-if="
                                                mealdetails.meal_details[0]
                                                  .lunch == true
                                              "
                                            >
                                              <v-item
                                                #default="{ active, toggle }"
                                              >
                                                <v-switch
                                                  @change="
                                                    toggle(!active),
                                                      inputObject1(
                                                        $event,
                                                        item,
                                                        'Lunch'
                                                      )
                                                  "
                                                  label="Lunch"
                                                  :input-value="active"
                                                  hide-details
                                                  inset
                                                  on
                                                  color="amber darken-2"
                                                  class="switchclass"
                                                ></v-switch>
                                              </v-item>
                                            </div>
                                            <div
                                              v-if="
                                                mealdetails.meal_details[0]
                                                  .dinner == true
                                              "
                                            >
                                              <v-item
                                                #default="{ active, toggle }"
                                              >
                                                <v-switch
                                                  @change="
                                                    toggle(!active),
                                                      inputObject1(
                                                        $event,
                                                        item,
                                                        'Dinner'
                                                      )
                                                  "
                                                  label="Dinner"
                                                  :input-value="active"
                                                  hide-details
                                                  inset
                                                  on
                                                  color="amber darken-2"
                                                  class="switchclass"
                                                ></v-switch>
                                              </v-item>
                                            </div>
                                            <v-item
                                              #default="{ active, toggle }"
                                            >
                                              <v-switch
                                                @change="
                                                  toggle(!active),
                                                    inputObject1(
                                                      $event,
                                                      item,
                                                      'Decide upon arrival'
                                                    )
                                                "
                                                label="Decide upon arrival"
                                                :input-value="active"
                                                hide-details
                                                inset
                                                on
                                                color="amber darken-2"
                                                class="switchclass"
                                              ></v-switch>
                                            </v-item>
                                          </v-item-group>
                                        </v-col>
                                      </v-row>
                                    </v-card>
                                  </div>
                                  <v-container>
                                    <h3>For Meal Modifications</h3>
                                    <v-card class="packagestyle">
                                      <v-row>
                                        <v-col cols="12" md="12">
                                          <v-textarea
                                            height="100px"
                                            v-model="mealmodification"
                                            label="Edit your meal"
                                            auto-grow
                                            outlined
                                            rows="1"
                                            color="yellow darken-4"
                                            row-height="15"
                                            class="local"
                                          ></v-textarea>
                                        </v-col>
                                      </v-row>
                                    </v-card>
                                  </v-container>
                                </div>
                              </v-container>
                            </v-card>
                          </v-dialog>
                        </h5>
                      </v-col>
                    </v-row>
                  </div>
                  <br />
                  <v-row>
                    <v-col v-if="this.midFlag" cols="4">
                      <h4 style="color: grey darken-1; margin-left: -10px">
                        Final Pay US${{ finalprice }}
                      </h4>
                    </v-col>

                    <v-col v-else cols="4">
                      <h4 style="color: grey darken-1; margin-left: -10px">
                        Final Pay US${{ totalPrice }}
                      </h4>
                    </v-col>
                  </v-row>

                  <br />
                  <v-row>
                    <v-divider style="max-width: 75%"></v-divider>
                  </v-row>
                  <br />
                  <br />

                  <div style="margin-left: -5px">
                    <v-row>
                      <h3 style="color: grey darken-1; padding: 5px">
                        Required for your trip
                      </h3>
                    </v-row>
                    <v-row class="switchstyle">
                      <v-col cols="8">
                        <h4 style="color: grey darken-1; margin-left: -07px">
                          Message The Host
                        </h4>
                        <p style="margin-left: -07px">
                          Let the host know why you are travelling and when you
                          will check in
                        </p>
                      </v-col>
                      <v-col cols="4">
                        <v-btn
                          style="
                            height: 20px;
                            min-width: 40px;
                            font-size: 10px;
                            margin-left: 15px;
                          "
                          >Add</v-btn
                        >
                      </v-col>
                    </v-row>

                    <v-row class="switchstyle">
                      <v-col cols="8">
                        <h4 style="color: grey darken-1; margin-left: -07px">
                          Phone Number
                        </h4>
                        <p style="margin-left: -07px">
                          Add and confirm phone number to get trip updates
                        </p>
                      </v-col>

                      <v-col cols="4">
                        <v-btn
                          style="
                            height: 20px;
                            min-width: 40px;
                            font-size: 10px;
                            margin-left: 15px;
                          "
                          >Add</v-btn
                        >
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-divider style="max-width: 75%"></v-divider>
                    </v-row>
                    <br />
                    <br />
                    <v-row>
                      <h4 style="color: grey darken-1; padding: 5px">
                        Cancellation Policy
                      </h4>
                    </v-row>
                    <v-row style="">
                      <p
                        style="
                          padding: 0 !important;
                          font-size: 14px;
                          margin-left: 4px;
                        "
                      >
                        Please check the tour provider's cancellation policy in
                        the package description.Tour provider reserves the right
                        to adjust/cancel any tour for unforeseen circumstances.
                        <b><u>Learn More</u></b>
                      </p>
                    </v-row>
                  </div>

                  <v-spacer></v-spacer>

                  <br />
                  <br />

                  <v-row>
                    <v-col style="padding: 0px">
                      <v-dialog
                        transition="dialog-bottom-transition"
                        max-width="500"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            style="
                              background-color: rgb(253, 237, 15);
                              border-radius: 6px;
                            "
                            v-bind="attrs"
                            v-on="on"
                            >Request For Package Quotation</v-btn
                          >
                        </template>
                        <template v-slot:default="dialog">
                          <v-card>
                            <v-toolbar flat>
                              <v-btn icon @click="dialog.value = false">
                                <v-icon small>mdi-close</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text
                              style="padding-top: 10px; padding-bottom: 0px"
                            >
                              <v-textarea
                                v-model="modificationNotes"
                                filled
                                label="Write here for Update Package"
                                placeholder="Note: If you don't need any updating just write N/A"
                              ></v-textarea>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                              <v-btn
                                color="yellow"
                                @click="(dialog.value = false), savePackage()"
                                >Save</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </template>
                      </v-dialog>
                    </v-col>
                    <!-- <v-col>
                      <div
                        v-if="
                          item.is_modification_request_active === 0 &&
                          !this.midFlag
                        "
                      >
                        <v-btn
                          style="
                            background-color: rgb(253, 237, 15);
                            border-radius: 6px;
                          "
                          @click="savePackage()"
                          >Request to Modify</v-btn
                        >
                      </div>
                    </v-col> -->
                  </v-row>
                  <br />
                </v-container>
              </div>

              <div class="col-sm-12 col-lg-5">
                <v-card class="cardstyle55">
                  <v-row>
                    <v-col
                      cols="6"
                      v-model="package_id"
                      class="d-flex child-flex"
                      style="padding-left: 25px"
                    >
                      <img
                        :src="item.banner_assets"
                        :lazy-src="item.banner_assets"
                        style="border-radius: 10px"
                      />
                    </v-col>
                    <v-col cols="6" style="padding-left: 10px">
                      <h3 class="exploretitle">{{ item.package_title }}</h3>
                      <br />
                      <h5 class="exploreclr exploremobile">
                        {{ item.pick_up_location }}
                      </h5>
                      <br />
                      <h5 class="exploreclr exploremobile">
                        {{ item.destination }}
                      </h5>
                      <br />
                      <div class="exploreclr" v-if="item.facilities.length > 0">
                        <v-row class="exploremobile">
                          <h5 class="bedstyl">
                            {{ _.join(item.facilities, ', ') }}
                          </h5></v-row
                        >
                      </div>
                      <br />
                      <div
                        class="exploreclr"
                        v-if="item.bed_details[0].single_bed == 1"
                      >
                        <v-row> <h5 class="bedstyl">Single bed</h5> </v-row>
                      </div>
                      <div
                        class="exploreclr"
                        v-if="item.bed_details[0].double_bed == 1"
                      >
                        <v-row> <h5 class="bedstyl">Double bed</h5> </v-row>
                      </div>
                      <div
                        class="exploreclr"
                        v-if="item.bed_details[0].triple_bed == 1"
                      >
                        <v-row> <h5 class="bedstyl">Triple bed</h5></v-row>
                      </div>
                      <div
                        class="exploreclr"
                        v-if="item.bed_details[0].dormitory == 1"
                      >
                        <v-row> <h5 class="bedstyl">Dormitory</h5></v-row>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row style="margin-left: 20px">
                    <v-divider style="max-width: 90%"></v-divider>
                  </v-row>
                  <v-row style="margin-left: 10px">
                    <p style="color: grey darken-1; padding: 5px">
                      <b>Package Price </b>US${{ item.price }}
                    </p>
                    <div v-if="this.midFlag">
                      <v-chip
                        class="ma-2"
                        color="light-green darken-1"
                        label
                        text-color="white"
                      >
                        <v-icon left> mdi-label </v-icon>
                        <h4 style="color: grey darken-1; padding: 5px">
                          Your modification request has been Approved
                        </h4>
                      </v-chip>
                    </div>
                  </v-row>
                </v-card>
                <br />
                <br />
              </div>
              <div class="col-sm-12 col-lg-5"></div>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
            </v-row>
          </div>
          <br />

          <br />
          <br />
        </v-container>
      </v-sheet>
    </div>
    <div class="loader-center" v-if="registrationFlag">
      <div class="atom-spinner">
        <div class="spinner-inner">
          <div class="spinner-line"></div>
          <div class="spinner-line"></div>
          <div class="spinner-line"></div>
          <div class="spinner-circle">
            <img src="../assets/img/logo-01.png" class="logostyle" alt="..." />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapState } from 'vuex';
import msgHelper from '../../utils/msg-helper';
import EventBus from '../../utils/EventBus';
// import Navbar from '../components/AppNavBar.vue';

export default {
  name: 'Payment',
  components: {
    // 'app-navbar': Navbar,
  },
  data() {
    return {
      modificationNotes: '',
      mealdetails: '',
      pricepernights: 0,
      flag: 0,
      isToggled: 0,
      service12: 0,
      service8: 0,
      service4: 0,
      start1: 0,
      end1: 0,
      startt: 0,
      endt: 0,
      daycount: '',
      packageFlag: false,
      registrationFlag: false,
      package_id: '',
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      finalArray: {},
      finalArray1: {},
      details: {},
      localtours: [],
      tour: false,
      e6: 1,
      switch2: true,
      switch1: true,

      localTourFlag: 0,
      mealFlag: 0,
      arrayToIterate: [],
      diff: '',
      dayFlag1: 0,
      dayFlag2: 0,
      toggle_exclusive: undefined,
      a: 1,
      alldetails: [],
      store: [],
      f_array: [],
      f_array1: [],
      values: [],
      values1: [],
      item: [],
      fab: false,
      localtourmodification: '',
      mealmodification: '',
      trip: {
        mealmodification: '',
        localtourmodification: '',
        name: '',
        location: null,
        start: null,
        end: null,
        multiple: false,
        group2: 0,
        multiple1: true,
        group1: 0,
      },
      group1: null,
      ab: [],
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      picker2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      adult: 0,
      totalPrice: 0,
      singlebedqty: 0,
      doublebedqty: 0,
      triplebedqty: 0,
      dormitoryqty: 0,
      singlebedprice: 0,
      doublebedprice: 0,
      triplebedprice: 0,
      dormitorybedprice: 0,
      singlebedtotalprice: 0,
      doublebedtotalprice: 0,
      triplebedtotalprice: 0,
      dormitorytotalbedprice: 0,
      children: 0,
      infrant: 0,
      startDate: 0,
      endDate: 0,
      date: null,
      dateStart: 0,
      dateEnd: 0,
      items: ['Credit Card', 'Debit Card', 'Master Card', 'Payments Wallet'],
      day: 0,
      mid: 0,
      midFlag: false,
      finalstartdate: 0,
      finalenddate: 0,
      guest: 0,
      finalprice: 0,
      package_admin_id: '',
      modified_package_id: '',
      group2: null,
      multiple: null,
    };
  },
  computed: {
    ...mapState(['user', 'token', 'pricing', 'packageId', 'packageDetails']),
  },

  async created() {
    const currentPath = this.$router.currentRoute;
    if (currentPath && currentPath.params) {
      const packageId = currentPath.params.packageId;
      this.$store.commit('setPackageId', packageId);
      await this.initPageDetails();
    }

    /* const isMId = currentPath.query?.mid;
    if (typeof isMId !== 'undefined') {
      localStorage.setItem('modified_package_id', currentPath.query.mid);
      this.midFlag = true;
      this.fetchModificationPackage(currentPath.query.mid);
    } else {
      localStorage.setItem('modified_package_id', '');
    } */
  },

  methods: {
    getMinDate() {
      if (this.dateStart !== '') {
        return new Date(this.dateStart * 1000).toISOString().substr(0, 10);
      }
    },
    /* async fetchModificationPackage(id) {
      try {
        const res = await this.$http.post(
          'fetchParticularModificationPackage',
          {
            modified_package_id: id,
          }
        );
        this.finalstartdate = res.data.data.start_date;
        this.finalenddate = res.data.data.end_date;
        this.guest = res.data.data.guest;
        this.finalprice = res.data.data.modification_price;
        const pricing = { ...this.pricing };
        pricing.total = this.finalprice;
        console.log('---pr', pricing);
        this.$store.commit('setPricing', pricing);
      } catch (e) {
        console.log(e);
      }
    }, */

    async initPageDetails() {
      try {
        this.flag = 1;
        await this.getPackageById();
        await this.init();
      } catch (e) {
        console.log(e);
      }
    },
    savePackage() {
      if (this.user === null) {
        EventBus.$emit('openSignInOrUpDialog', false);
        msgHelper.showToast(
          'warning',
          'Please login before proceeding to modification for the package'
        );
      }
      let payload = {
        modification_notes: this.modificationNotes,
        package_id: this.packageId,
        local_tour_user_input: this.localtourmodification,
        meal_user_input: this.mealmodification,
        local_tours: this.f_array,
        meal_details: this.f_array1,
        is_approved: 0,
        is_ignore: 0,
        user_id: this.user ? this.user._id : '',
        total_price: this.totalPrice,
        guest: {
          adult: Number(this.adult),
          child: Number(this.children),
          infant: Number(this.infrant),
        },
        start_date: this.start1,
        end_date: this.end1,
      };

      this.$http
        .post('createPackageModification', {
          payload,
          packageModification: this.packageDetails,
        })
        .then(() => {
          msgHelper.showToast(
            'success',
            'Your request has been successfully send to Admin!'
          );
          this.getPackageById();
        })
        .catch(() => {
          this.$swal({
            title: 'Oops...',
            text: 'Something went wrong!',
            icon: 'Error',
          });
        });
    },

    dateConvertForStartDate() {
      this.dateStart = moment(
        this.dateStart + ' 23:59',
        'YYYY-MM-DD HH:mm'
      ).unix();
    },
    Dateconvertforenddate() {
      this.dateEnd = moment(this.dateEnd + ' 23:59', 'YYYY-MM-DD HH:mm').unix();
    },
    convertDate(unixDate) {
      return moment.unix(unixDate).format('Do MMMM,YYYY');
    },
    payment() {
      const token = this.token;
      if (token && token !== 'null') {
        this.$router.push({ name: 'Make Payment' });
      } else {
        // this.$router.replace({ name: 'Customer' });
        EventBus.$emit('openSignInOrUpDialog', false);
        // EventBus.$on(this.$router.go(0))
      }
    },
    Edit() {
      const token = this.token;
      if (token && token !== 'null') {
        this.$router.currentRoute;
      } else {
        this.$router.replace({ name: 'Customer' });
      }
    },
    async getPackageById() {
      console.log(this.packageId);
      this.registrationFlag = true;
      try {
        const res = await this.$http.post('fetchParticularPackage', {
          package_id: this.packageId,
          user_id: this.user ? this.user._id : '',
        });
        this.$store.commit('setPackageDetails', res.data.data);
        this.item = res.data.data;
        this.package_admin_id = res.data.data.package_owner[0];

        localStorage.setItem('package_admin_id', this.package_admin_id);

        this.mealdetails = res.data.data;
      } catch (e) {
        console.log(e);
      }
      this.registrationFlag = false;
    },
    async init() {
      this.finalArray = {};
      this.finalArray1 = {};

      this.start1 = localStorage.getItem('startDate');
      this.end1 = localStorage.getItem('endDate');
      this.finalArray1 = JSON.parse(localStorage.getItem('service1'));
      this.finalArray = JSON.parse(localStorage.getItem('service'));
      this.details = localStorage.getItem('details');
      this.adult = parseInt(localStorage.getItem('adult'));
      this.children = parseInt(localStorage.getItem('children'));
      this.infrant = parseInt(localStorage.getItem('infrant'));

      if (this.finalArray && this.finalArray1) {
        this.values = Object.keys(this.finalArray);
        this.values1 = Object.keys(this.finalArray1);
        this.arrayToIterate = this.getDates(this.start1, this.end1);
        this.day = this.arrayToIterate.length;
        localStorage.setItem('daycount', this.day);
        for (let i in this.arrayToIterate) {
          this.finalArray[this.arrayToIterate[i]] = {};
          this.finalArray1[this.arrayToIterate[i]] = {};
        }
      }
      this.arrayToIterate = await this.getDates(this.start1, this.end1);
      this.pricing.packagePrice = this.packageDetails.price;
      this.pricing.daysCount = this.arrayToIterate.length - 1;
      this.pricing.noOfCustomers =
        parseInt(localStorage.getItem('adult')) +
        parseInt(localStorage.getItem('children'));
      console.log('verify ', this.pricing);

      this.calculate();
    },
    calculate() {
      this.start1 = localStorage.getItem('startDate');
      this.end1 = localStorage.getItem('endDate');
      this.finalArray = {};
      this.finalArray1 = {};

      this.finalArray1 = JSON.parse(localStorage.getItem('service1'));
      this.finalArray = JSON.parse(localStorage.getItem('service'));
      this.singlebedqty = localStorage.getItem('Singlebed');
      this.doublebedqty = localStorage.getItem('Doublebed');
      this.triplebedqty = localStorage.getItem('Triplebed');
      this.dormitoryqty = localStorage.getItem('Dormitory');
      this.singlebedprice = localStorage.getItem('singlebedprice');
      this.doublebedprice = localStorage.getItem('doublebedprice');
      this.triplebedprice = localStorage.getItem('triplebedprice');
      this.dormitorybedprice = localStorage.getItem('dormitorybedprice');

      // Bed Price Details
      var singlePrice, doublePrice, triplePrice, dormitoryPrice;
      singlePrice = parseInt(this.singlebedqty) * parseInt(this.singlebedprice);
      this.singlebedtotalprice = isNaN(singlePrice) ? 0 : singlePrice;

      doublePrice = parseInt(this.doublebedqty) * parseInt(this.doublebedprice);
      this.doublebedtotalprice = isNaN(doublePrice) ? 0 : doublePrice;

      triplePrice = parseInt(this.triplebedqty) * parseInt(this.triplebedprice);
      this.triplebedtotalprice = isNaN(triplePrice) ? 0 : triplePrice;

      dormitoryPrice =
        parseInt(this.dormitoryqty) * parseInt(this.dormitorybedprice);
      this.dormitorytotalbedprice = isNaN(dormitoryPrice) ? 0 : dormitoryPrice;

      this.totalPrice =
        this.pricing.packagePrice *
          this.pricing.noOfCustomers *
          this.pricing.daysCount +
        parseInt(this.singlebedtotalprice) +
        parseInt(this.doublebedtotalprice) +
        parseInt(this.triplebedtotalprice) +
        parseInt(this.dormitorytotalbedprice);
      this.pricing.total = this.totalPrice;
      console.log(this.pricing);

      this.$store.commit('setPricing', this.pricing);

      /** Child price calculations (NOT NEEDED)
       * if (childCount > 0) {
        const pkgPrice = parseInt(price) / 2;
        // eslint-disable-next-line prettier/prettier
        this.totalPrice = this.totalPrice + pkgPrice * childCount;
      } */
      localStorage.setItem('totalprice', this.totalPrice);
      this.flag = 0;
    },

    inputObject(e, date, item) {
      let date_d = moment(date + ' 23:59', 'DD-MM-YYYY HH:mm').unix();
      let data_found = this.f_array.findIndex((obj) => obj.date == date_d);
      if (data_found >= 0) {
        this.f_array.splice(
          this.f_array.findIndex((obj) => obj.date == date_d),
          1
        );
        let hrs = item;
        let tour_object = {
          date: date_d,
          tour_service_for: Number(hrs),
        };
        this.f_array.push(tour_object);
      } else {
        let hrs = item;
        let tour_object = {
          date: date_d,
          tour_service_for: Number(hrs),
        };
        this.f_array.push(tour_object);
      }
    },

    inputObject1(e1, date, item) {
      let date_d1 = moment(date + ' 23:59', 'DD-MM-YYYY HH:mm').unix();

      if (this.f_array1.length > 0) {
        let data_found1 = this.f_array1.findIndex((obj) => obj.date == date_d1);
        if (data_found1 >= 0) {
          this.f_array1.splice(
            this.f_array1.findIndex((obj) => obj.date == date_d1),
            1
          );
          let meal = item;
          let meal_object = {
            date: date_d1,
            selected_meal: meal,
          };
          this.f_array1.push(meal_object);
        } else {
          let meal = item;
          let meal_object = {
            date: date_d1,
            selected_meal: meal,
          };
          this.f_array1.push(meal_object);
        }
      } else {
        let meal = item;
        let meal_object = {
          date: date_d1,
          selected_meal: meal,
        };
        this.f_array1.push(meal_object);
      }
    },

    async page1() {
      this.packageFlag = true;
      const services = JSON.parse(JSON.stringify(this.finalArray));
      const meals = JSON.parse(JSON.stringify(this.finalArray1));
      // Saving the details
      window.localStorage.setItem('service', JSON.stringify(services));
      window.localStorage.setItem('service1', JSON.stringify(meals));

      (this.finalArray = {}), (this.finalArray1 = {});
      this.finalArray1 = JSON.parse(localStorage.getItem('service1'));
      this.finalArray = JSON.parse(localStorage.getItem('service'));
      if (this.finalArray && this.finalArray1) {
        this.values = Object.keys(this.finalArray);
        this.values1 = Object.keys(this.finalArray1);
      }
      await this.$nextTick();
    },
    openSwitch() {
      this.tour = false;
    },
    onScroll(e) {
      if (typeof window === 'undefined') return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },

    async toggle() {
      this.startDate = localStorage.getItem('startDate');
      this.endDate = localStorage.getItem('endDate');
      this.arrayToIterate = [];
      this.arrayToIterate = await this.getDates(this.startDate, this.endDate);
      this.a = this.arrayToIterate.length;
      this.arrayToIterate = this.arrayToIterate.slice(0, 7);
      this.finalArray = {};
      this.finalArray1 = {};
      if (this.arrayToIterate.length > 0) {
        for (let i in this.arrayToIterate) {
          this.finalArray[this.arrayToIterate[i]] = {};
        }

        for (let j in this.arrayToIterate) {
          this.finalArray1[this.arrayToIterate[j]] = {};
        }
      }
    },
    getDates(start1, end1) {
      const dateArray = [];
      let startDate = moment.unix(parseInt(start1));
      let endDate = moment.unix(parseInt(end1));
      this.startDate = startDate;
      this.endDate = endDate;
      while (startDate <= endDate) {
        dateArray.push(startDate.format('DD-MM-YYYY'));
        startDate = moment(startDate).add(1, 'days');
      }
      return dateArray;
    },

    switched() {
      this.localTourFlag = !this.localTourFlag;
    },
    mealSelected() {
      this.mealFlag = !this.mealFlag;
    },
    openDay1() {
      this.dayFlag1 = !this.dayFlag1;
    },
    openDay2() {
      this.dayFlag2 = !this.dayFlag2;
    },
    mealDeselected() {
      this.mealFlag = 0;
      this.mealFlag2 = 0;
      this.mealFlag3 = 0;
    },
    closeDay() {
      console.log('please fill');
      this.dayFlag1 = 0;
      this.dayFlag2 = 0;
    },

    closeMeal() {
      this.mealFlag2 = 0;
      this.mealFlag3 = 0;
      this.mealFlag = 0;
    },

    navigateToPackageDetails(packageId) {
      this.$router.push({
        name: 'Package Details',
        params: {
          packageId,
        },
      });
    },
    page() {
      localStorage.setItem('startDate', this.dateStart);
      localStorage.setItem('endDate', this.dateEnd);
      this.$router.push({ name: 'Payment' });
    },
    pageguest() {
      localStorage.setItem('adult', this.adult);
      localStorage.setItem('children', this.children);
      localStorage.setItem('infrant', this.infrant);
      this.$router.push({ name: 'Payment' });
    },

    decrement() {
      this.adult--;
    },
    increment() {
      this.adult++;
    },
    decrement1() {
      this.children--;
    },
    increment1() {
      this.children++;
    },
    decrement2() {
      this.infrant--;
    },
    increment2() {
      this.infrant++;
    },
  },
};
</script>

<style scoped src="../assets/css/style.css">
.card-div {
  height: 40px;
}
v-row {
  padding: 0px !important;
}
</style>
